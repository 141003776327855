import React from 'react';
import PageTitle from "../components/layout/PageTitle";
import ContainerMedium from "../components/layout/ContainerMedium";
import PageCard from "../components/layout/PageCard";

const PartsSearchPage = () => {
    return (
        <section>
            <PageTitle>Parts Search - Coming Soon!</PageTitle>
            <ContainerMedium>
                <PageCard>
                    <p className={'text-center m-5 mt-0'}>
                        We're still working on this and will launch soon.
                    </p>
                    <p className={'text-center m-5'}>
                        When it's up, you'll be able to search for parts, match for fitment with your vehicle data and order right here!
                    </p>
                </PageCard>
            </ContainerMedium>
        </section>
    );
};

export default PartsSearchPage;
