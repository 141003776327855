import React from 'react';
import { useEffect, useState } from 'react';
import { getCategories } from '../services/categoryService';
import {get} from "../utils/http";

const CategoryGrid = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategoriesData = async () => {
            const categoriesData = await getCategories();
            console.log(categoriesData, 'cat data')
            setCategories(categoriesData);
        };
        fetchCategoriesData();
    }, []);

    return (
        <section className="py-16">
            <div className="container mx-auto">
                <h2 className="text-3xl font-semibold mb-6 text-center">Shop by Category</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8">
                    {categories.map((category) => (
                        <div key={category.id} className="bg-white shadow-md rounded-md overflow-hidden text-center">
                            <img src={category.image} alt={category.name} className="w-full h-60 object-fit-contain p-10" />
                            <div className="p-4">
                                <h3 className="text-lg font-bold"><strong>{category.name}</strong></h3>
                                <p className="text-gray-600 mt-2">{category.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CategoryGrid;
