import React from 'react';
import { Box, Typography } from '@mui/material';

const Hero = () => {
    return (
        <Box
            sx={{
                pt: 8,
                pb: 8,
                mt: -5,
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundImage: `url(/images/parts-dark-room.png)`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    opacity: 1,
                    zIndex: -1,
                }}
            />
            <Box sx={{ textAlign: 'center', color: 'white' }}>
                <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold', mb: 2, color: 'white', fontSize: '3em' }}>
                    Shocks, CV Joints, suspension parts & <strong>great service</strong>.
                </Typography>
                <Typography variant="h5" sx={{ mb: 4, color: '#FFFFFF' }}>
                    We were chosen for a purpose: to keep you aligned and safe on our roads.
                </Typography>
                <button className="px-4 py-2 text-white bg-orange-500 rounded hover:bg-orange-600">
                    Check out these <strong>ROAD SAFETY TIPS</strong>
                </button>
            </Box>
        </Box>
    );
};

export default Hero;
