import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import CallToAction from './components/CallToAction';
import CategoryGrid from './components/CategoryGrid';
import TestimonialGrid from './components/TestimonialGrid';
import BlogGrid from './components/BlogGrid';
import Order from './pages/Order';
import Delivery from './pages/Delivery';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from "./pages/Home";
import PartsSearch from "./pages/PartsSearch";
import VehicleData from "./pages/VehicleData";
import AutoBlog from "./pages/AutoBlog";
import ThankYouPage from "./pages/ThankYou";
import ContactSubmittedPage from "./pages/ContactSubmitted";

function App() {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow mt-16">
                    <div className="my-6">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/thank-you" element={<ThankYouPage />} />
                            <Route path="/contact-submitted" element={<ContactSubmittedPage />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/order" element={<Order />} />
                            <Route path="/parts" element={<PartsSearch />} />
                            <Route path="/vehicle-data" element={<VehicleData />} />
                            <Route path="/blog/*" element={<AutoBlog />} />
                        </Routes>
                    </div>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
