import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Button,
    Stack,
    useMediaQuery,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
} from '@mui/material';
import {Close, Menu, Search} from '@mui/icons-material';
import logo from '../assets/images/fa-logo-h.png';
import { createTheme } from '@mui/material/styles';
import {createDraft} from "../services/orderService";

const theme = createTheme({
    palette: {
        primary: {
            main: '#B60000',
        },
        secondary: {
            main: '#FFC107',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

const menuItems = [
    {
        label: 'Parts Search',
        path: '/parts',
    },
    {
        label: 'Vehicle Data',
        path: '/vehicle-data',
    },
    {
        label: 'Auto Blog',
        path: '/blog',
    },
    {
        label: 'About',
        path: '/about',
    },
    {
        label: 'Contact Us',
        path: '/contact',
    },
];

export default function Header() {
    const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleSearchSubmit = async (event) => {
        try {
            const quotation = await createDraft(searchValue);
            const quotationId = quotation.quotationId;
            localStorage.setItem('temporary_quote_token', quotation.token);
            window.location.assign(`/order?quotationId=${quotationId}`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#FFFFFF', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }} elevation={1}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="row" alignItems="center">
                    {isMobile ? (
                        <>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2, color: theme.palette.primary.main }}
                                onClick={toggleDrawer}
                            >
                                <Menu />
                            </IconButton>
                            <Link to="/" className={'text-center'}>
                                <img src={logo} alt="Logo" style={{ height: '20px' }} />
                            </Link>
                        </>
                    ) : (
                        <Link to="/">
                            <img src={logo} alt="Logo" style={{ height: '50px' }} />
                        </Link>
                    )}
                    {!isMobile && (
                        <Stack direction="row" spacing={2} alignItems="center">
                            {menuItems.map((menuItem) => (
                                <Button
                                    key={menuItem.path}
                                    component={Link}
                                    to={menuItem.path}
                                    color="inherit"
                                    sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
                                >
                                    {menuItem.label}
                                </Button>
                            ))}
                        </Stack>
                    )}
                </Stack>
                {!isMobile && (
                    <Stack direction="row" alignItems="center">
                        <InputBase
                            sx={{ bgcolor: 'common.white', borderRadius: '4px' }}
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    handleSearchSubmit();
                                }
                            }}
                        />
                        <Button variant="contained" sx={{ ml: 1 }} onClick={handleSearchSubmit}>
                            <Search />
                        </Button>
                    </Stack>
                )}
                {isMobile && (
                    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, color: theme.palette.primary.main }}
                            onClick={toggleDrawer}
                            className={'pull-right'}
                        >
                            <Close />
                        </IconButton>
                        <List sx={{ width: '200px' }}>
                            {menuItems.map((menuItem) => (
                                <ListItem
                                    button
                                    key={menuItem.path}
                                    component={Link}
                                    to={menuItem.path}
                                    onClick={toggleDrawer}
                                >
                                    <ListItemText primary={menuItem.label} />
                                </ListItem>
                            ))}
                            <ListItem sx={{ mt: 'auto' }}>
                                <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                                    <InputBase
                                        sx={{ bgcolor: 'common.white', borderRadius: '4px' }}
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                        onKeyDown={handleSearchSubmit}
                                    />
                                    <IconButton aria-label="search" onClick={handleSearchSubmit}>
                                        <Search />
                                    </IconButton>
                                </Stack>
                            </ListItem>
                        </List>
                    </Drawer>
                )}
            </Toolbar>
        </AppBar>
    );
}