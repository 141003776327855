import React from 'react';
import Hero from '../components/Hero';
import CallToAction from '../components/CallToAction';
import CategoryGrid from '../components/CategoryGrid';
import TestimonialGrid from '../components/TestimonialGrid';
import BlogGrid from '../components/BlogGrid';

const LandingPage = () => {
    return (
        <>
            <Hero />
            <CallToAction />
            <CategoryGrid />
            <TestimonialGrid />
            <BlogGrid />
        </>
    );
};

export default LandingPage;
