import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="border-t border-white border-opacity-25 mx-auto px-4 sm:px-6">
                <div className="max-w-7xl mx-auto">
                    <div className="flex flex-wrap justify-center space-x-4">
                        <div className="my-4">
                            <a href="#" className="text-white hover:text-gray-300">
                                <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                        </div>
                        <div className="my-4">
                            <a href="#" className="text-white hover:text-gray-300">
                                <FontAwesomeIcon icon={faTwitter} size="2x" />
                            </a>
                        </div>
                        <div className="my-4">
                            <a href="#" className="text-white hover:text-gray-300">
                                <FontAwesomeIcon icon={faInstagram} size="2x" />
                            </a>
                        </div>
                    </div>
                    <div className="text-center text-sm py-4">
                        Brought to you by Front-end Auto, c/o C5 Market Limited
                        <br />
                        <small>This application was built in a Software Factory, in Jamaica.
                        <br /><br />
                        <smalll>Software version: 0.0.1 - MVP, released May 11, 2023 at 4:22 pm</smalll>
                        </small>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
