import React from 'react';
import PageTitle from "../components/layout/PageTitle";
import ContainerMedium from "../components/layout/ContainerMedium";
import PageCard from "../components/layout/PageCard";

const VehicleDataPage = () => {
    return (
        <section>
            <PageTitle>Vehicle Data - Coming Soon!</PageTitle>
            <ContainerMedium>
                <PageCard>
                    <p className={'text-center'}>
                        We have loads of data on thousands of vehicles from different makes, models and other specs.
                    </p>
                    <p className={'text-center m-5'}>
                        Our vehicle data site is being prepared to provide you with the most accurate and informative data on a vehicle you might want to purchase, or that you're just curious about.
                    </p>
                    <p className={'text-center m-5'}>
                        It's on the way!
                    </p>
                </PageCard>
            </ContainerMedium>
        </section>
    );
};

export default VehicleDataPage;
