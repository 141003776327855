import React from 'react';

const PageCard = ({ children }) => {
    return (
        <div className="bg-white shadow-md rounded-md p-8">
            <div className="text-2xl font-light font-raleway leading-9 mb-6">{children}</div>
        </div>
    );
};

export default PageCard;
