import React from 'react';
import { useEffect, useState, useRef } from 'react';
import TestimonialCard from './TestimonialCard';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as httpClient from "../utils/http";

const TestimonialsGrid = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef(null);
    const slideIntervalRef = useRef(null);

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await httpClient.get('/testimonials');
                setTestimonials([...response.data, ...response.data]);
            } catch (error) {
                console.log(error);
            }
        };

        fetchTestimonials();

        // Start the slide interval
        slideIntervalRef.current = setInterval(() => {
            setCurrentSlide((prev) => prev === testimonials.length - 1 ? 0 : prev + 1);
        }, 8000);

        // Stop the slide interval on unmount
        return () => clearInterval(slideIntervalRef.current);
    }, []);

    const handlePrevSlide = () => {
        setCurrentSlide((prev) => prev <= 0 ? testimonials.length - 1 : prev - 1);
    };

    const handleNextSlide = () => {
        setCurrentSlide((prev) => prev >= testimonials.length - 1 ? 0 : prev + 1);
    };

    return (
        <div className="py-16">
            <div className="mx-auto bg-cover bg-center red-background-div">
                <div className={"container p-10"}>
                    <h2 className="text-3xl font-bold mb-6 text-center text-white">What Our Customers Say</h2>
                    <div className="relative">
                        <div className="carousel" ref={carouselRef}>
                            {testimonials.map((testimonial, index) => (
                                <div
                                    key={testimonial.id}
                                    className={`slide ${index === currentSlide ? 'active' : ''}`}
                                >
                                    <TestimonialCard {...testimonial} />
                                </div>
                            ))}
                        </div>
                        <button
                            className="carousel-control left"
                            onClick={handlePrevSlide}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <button
                            className="carousel-control right"
                            onClick={handleNextSlide}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TestimonialsGrid;
