import axios from 'axios';
import notification from "./notification";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_HOST || 'http://localhost:6001/api'
});

export const get = async (url) => {
    const response = await httpClient.get(url);
    return response;
};

export const post = async (url, data) => {
    const response = await httpClient.post(url, data);
    if ( response.data?.notification ) notification(response.data.notification);
    return response;
};
