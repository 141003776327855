import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

const TestimonialCard = ({ name, quote, image }) => {
    return (
        <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            <div className="md:flex">
                <div className="p-8">
                    <div className="uppercase tracking-wide text-4xl text-red-700 font-bold text-center">
                        {name}
                    </div>
                    <div className="mt-5 text-gray-600 text-xl font-raleway leading-relaxed text-center">
                        <FontAwesomeIcon icon={faQuoteLeft} className="inline-block mr-2" />
                        {quote}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;
