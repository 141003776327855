import axios from 'axios';

const api = axios.create({
    timeout: 10000, // 10 seconds
    headers: {
        'Content-Type': 'application/json',
    },
});

export const submitContactForm = async (data) => {
    try {
        const response = await api.post('/contact', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
