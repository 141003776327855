import Swal from 'sweetalert2';

const notification = args => {
    let {text, type} = args;
    Swal.fire({
        text,
        icon: type,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });
};

export default notification;
