import React from 'react';
import PageTitle from "../components/layout/PageTitle";
import ContainerMedium from "../components/layout/ContainerMedium";
import PageCard from "../components/layout/PageCard";
import logo from '../assets/images/fa-logo-insignia.png';

const ThankYouPage = () => {
    return (
        <section>
            <PageTitle>Thank You!</PageTitle>
            <ContainerMedium>
                <PageCard>
                    <p className={'text-center m-5'}><strong>We've received your request and will be in touch with you.</strong></p>
                    <p className={'text-center m-5'}>In case you need to reach out, feel free to call us at 876-572-5184/288-2867, or send us an email at: business@frontendauto.com.</p>

                </PageCard>
            </ContainerMedium>
        </section>
    );
};

export default ThankYouPage;
