import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';

console.log(window.location.href);

if (window.location.protocol === 'http:' && window.location.host.indexOf('local') < 0) {
    const httpsUrl = window.location.href.replace(/^http:/, 'https:');
    window.location.assign(httpsUrl);
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// reportWebVitals();
