import React from 'react';
const PageTitle = (props) => {
    return (
        <div className="container mx-auto px-4 pt-6 md:w-2/3 lg:w-1/2">
            <h1 className="text-4xl font-bold text-center mt-10 mb-8">{props.children}</h1>
        </div>
    );
};

export default PageTitle;
