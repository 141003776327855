import React from 'react';
import { useState } from 'react';
import {get, post} from '../utils/http';
import PageTitle from "../components/layout/PageTitle";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const coords = { lng: -77.2525893, lat: 17.9745222 };

const MapComponent = withScriptjs(
    withGoogleMap(() => (
        <GoogleMap
            defaultZoom={18}
            defaultCenter={{ lat: coords.lat, lng: coords.lng }}
        >
            <Marker position={{ lat: coords.lat, lng: coords.lng }} />
        </GoogleMap>
    ))
);

export default function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await post('/contact', {
                name,
                email,
                phoneNumber,
                message,
            });
            setName('');
            setEmail('');
            setPhoneNumber('');
            setMessage('');
            window.location.assign('/contact-submitted');
        } catch (error) {
            console.error(error);
            // handle error
        }
    };

    return (
        <div>
            <PageTitle>Contact Us</PageTitle>
            <div className="w-full h-screen text-center">
                <p>We're located at 2 West Park Avenue, May Pen in Clarendon. See our location below.</p>
                <br />
                <MapComponent
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyD_meiNCLghZ_hef8blqE_bgOI97nwb6gU`}
                    loadingElement={<div className="h-full" />}
                    containerElement={<div className="h-full" />}
                    mapElement={<div className="h-full" />}
                />
            </div>
            <br />
            <br />
            <div className="container mx-auto px-4 py-6 md:w-2/3 lg:w-1/2">
                <p>Feel free to leave us a message in the form below.</p>
                <br />
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2 md:inline-block md:w-1/3">
                            Name:
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:inline-block md:w-2/3"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2 md:inline-block md:w-1/3">
                            Email:
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:inline-block md:w-2/3"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2 md:inline-block md:w-1/3">
                            Phone number:
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:inline-block md:w-2/3"
                            type="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold mb-2 md:inline-block md:w-1/3">
                            Message:
                        </label>
                        <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline md:inline-block md:w-2/3"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button
                        className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Send
                    </button>
                </form>
            </div>
        </div>
    );
}
