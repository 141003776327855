import React from 'react';
import { useState } from 'react';
import {Box, Button, Typography} from '@mui/material';
import { createDraft } from '../services/orderService';

const CallToAction = () => {
    const [partName, setPartName] = useState('');
    const [quotationId, setQuotationId] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const quotation = await createDraft(partName);
            setQuotationId(quotation.quotationId);
            localStorage.setItem('temporary_quote_token', quotation.token);
        } catch (error) {
            console.log(error);
        }
    };

    if (quotationId) {
        return window.location.assign(`/order?quotationId=${quotationId}`);
    }

    return (

        <Box
            sx={{
                background: 'linear-gradient(to bottom, #F2F2F2, #E5E5E5)',
                p: 5,
                textAlign: 'center',
            }}
        >
            <h2 className="text-3xl font-semibold mb-6 text-center">What parts are you shopping for now?</h2>
            <form onSubmit={handleSubmit} className="mx-auto max-w-lg flex items-center">
                <input
                    type="text"
                    placeholder="E.g. shocks, control arm, tie rod ends"
                    className="@apply px-4 py-2 border-2 border-gray-300 rounded-lg mr-4 w-full"
                    value={partName}
                    onChange={(event) => setPartName(event.target.value)}
                />
                <Button variant="contained" size="large" color="primary" type="submit">
                    Continue
                </Button>
            </form>
        </Box>
    );
};

export default CallToAction;
