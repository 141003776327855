import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as httpClient from '../utils/http';

const BlogGrid = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await httpClient.get('/articles');
                setArticles(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <div className="mb-15">
            <div className="container mx-auto">
                <h2 className="text-3xl font-semibold mb-6 text-center">Articles from our Automotive Blogs</h2>
                <ul className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {articles.map((article) => (
                        <li key={article.id} className="bg-white shadow rounded-lg overflow-hidden">
                            <img
                                className="w-full h-70 object-fit-contain"
                                src={article.image}
                                alt={article.title}
                            />
                            <div className="p-4">
                                <h3 className="text-lg font-semibold mb-2">{article.title}</h3>
                                <p className="text-gray-700 text-base mb-2">{article.description}</p>
                                <Link
                                    to={`/blog/${article.id}`}
                                    className="text-sm font-semibold text-indigo-600"
                                >
                                    Read more &rarr;
                                </Link>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default BlogGrid;
