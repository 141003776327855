import React from 'react';
import PageTitle from "../components/layout/PageTitle";
import ContainerMedium from "../components/layout/ContainerMedium";
import PageCard from "../components/layout/PageCard";
import logo from '../assets/images/fa-logo-insignia.png';

const AboutPage = () => {
    return (
        <section>
            <PageTitle>Front-end Auto</PageTitle>
            <ContainerMedium>
                <PageCard>
                    <p className={'text-center m-5 mt-0 font-bold'}>
                        <img src={logo} alt="Logo" style={{ height: '10em' }} className="mx-auto block" />
                    </p>
                    <p className={'text-center m-5'}><strong>We're a small start-up with big goals, focused on delivering exceptional service to Jamaican vehicle owners.</strong></p>
                    <p className={'text-center m-5'}>Our specialty is front-end and suspension parts. That's what we'd like to be remembered for, and as the company that kept your vehicle running smoothly, with perfect alignment and masterful handling of bad surfaces.</p>
                    <p className={'text-center m-5'}>Jamaica -- well, umm...every country in the world -- has bad roads. You should drive carefully and slow dance with those potholes and humps, but you need to keep your suspension and front-end parts in check!</p>
                    <p className={'text-center m-5'}>Come here for the suspension parts, but remember that we're hardcore automotive, so you can stay for the other great items we have in stock.</p>
                    <p className={'text-center m-5 font-medium'}>And another thing...when you go to get your car checked out on the alignment stand, they will give you a list of parts you need to get. Guess where you'll get those parts?</p>

                    <p className={'text-center m-5 text-md font-light text-indigo-900'}>Front-end Auto is a subsidiary of C5 Market Limited. Delivery and sourcing are powered by <a href={"https://portal.airshipcouriers.com"} target={"_blank"}>AirShip Couriers</a>.</p>

                </PageCard>
            </ContainerMedium>
        </section>
    );
};

export default AboutPage;
