import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import PageTitle from '../components/layout/PageTitle';
import {post, get} from "../utils/http";
import ReCAPTCHA from "react-google-recaptcha";

const OrderPage = ({ location }) => {
    const [formData, setFormData] = useState({
        name: '',
        part: '',
        vehicle: '',
        contactNumber: '',
        email: '',
        chassisNumber: '',
        details: '',
        photo: null,
    });

    useEffect(() => {
        const fetchQuotation = async () => {
            const quotationId = new URLSearchParams(window.location.search).get(
                'quotationId'
            );
            if (quotationId) {
                try {
                    const response = await get(`/quotations/${quotationId}/${localStorage.temporary_quote_token}`);
                    setFormData((prevState) => ({
                        ...prevState,
                        part: response.data.part,
                    }));
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        text:
                            error.response?.data?.notification?.text ||
                            'An error occurred. Please try again.',
                        confirmButtonText: 'OK',
                    });
                }
            }
        };
        fetchQuotation();
        getToken();
    }, [window.location.search]);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'file' ? target.files[0] : target.value;
        const name = target.name;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!tokenVerified) {
            return Swal.fire({
                icon: 'error',
                text: `Are you human? Please check the box "I'm not a robot". We're playing our part in helping to fight online spam and abuse.`,
                confirmButtonText: 'OK',
            });
        }

        const formDataToSend = new FormData();
        formDataToSend.append('part', formData.part);
        formDataToSend.append('name', formData.name);
        formDataToSend.append('vehicle', formData.vehicle);
        formDataToSend.append('contactNumber', formData.contactNumber);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('chassisNumber', formData.chassisNumber);
        formDataToSend.append('details', formData.details);
        formDataToSend.append('photo', formData.photo);
        formDataToSend.append('token', localStorage.temporary_quote_token);
        formDataToSend.append('humanToken', humanToken);

        if ( formData.name ) localStorage.setItem('visitorName', formData.name);
        if ( formData.contactNumber ) localStorage.setItem('visitorPhoneNumber', formData.contactNumber);
        if ( formData.email ) localStorage.setItem('visitorEmail', formData.email);

        try {
            const response = await post(`/quotations/${localStorage.temporary_quote_token}/update`, formDataToSend);
            Swal.fire({
                icon: 'success',
                text: `Thank you for your inquiry. We'll get back to you soon.`,
                confirmButtonText: 'OK',
            });
            localStorage.removeItem('temporary_quote_token');
            setFormData((prevState) => ({
                ...prevState,
                part: '',
                name: '',
                vehicle: '',
                contactNumber: '',
                email: '',
                chassisNumber: '',
                details: '',
                photo: null,
            }));
            window.setTimeout(() => window.location.assign('/thank-you'), 3000);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                text:
                    error.response?.data?.notification?.text ||
                    'An error occurred. Please try again.',
                confirmButtonText: 'OK',
            });
            setTokenVerified(false);
            getToken();
        }
    };

    const [humanToken, setHumanToken] = useState('');
    const [tokenVerified, setTokenVerified] = useState(false);
    const [tokenChecked, setTokenChecked] = useState(false);

    const getToken = async () => {
        const response = await post(`/token/new`);
        if ( response.data.token ) setHumanToken(response.data.token);
    };

    const handleCaptchaCheck = async () => {
        setTokenChecked(true);
        const response = await post(`/token/verify`, { token: humanToken });
        if ( response.data.verified ) {
            setTokenVerified(true);
        } else {
            setTokenVerified(false);
        }
    };

    return (
        <div>
            <PageTitle>Order your Parts</PageTitle>
            <form onSubmit={handleSubmit} className="mx-auto px-4 grid grid-cols-2 md:grid-cols-1 md:w-4/5 lg:w-4/5">
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="p-6 bg-gray-200">
                    <label htmlFor="part" className="block">Part Name(s):</label>
                    <input
                        type="text"
                        id="part"
                        name="part"
                        value={formData.part}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                    <label htmlFor="vehicle" className="block">Vehicle:</label>
                    <input
                        type="text"
                        id="vehicle"
                        name="vehicle"
                        value={formData.vehicle}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                    <label htmlFor="chassisNumber" className="block">Chassis Number:</label>
                    <input
                        type="text"
                        id="chassisNumber"
                        name="chassisNumber"
                        value={formData.chassisNumber}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                    <label htmlFor="photo" className="block">Photo:</label>
                    <input
                        type="file"
                        id="photo"
                        name="photo"
                        accept="image/*"
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="p-6 bg-gray-300">
                    <label htmlFor="contact" className="block">Contact Number:</label>
                    <input
                        type="text"
                        id="contactNumber"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                    <label htmlFor="name" className="block">Contact Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                    <label htmlFor="contact" className="block">Email:</label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                    <label htmlFor="description" className="block">Details:</label>
                    <textarea
                        id="details"
                        name="details"
                        value={formData.details}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 rounded-lg border border-gray-400 focus:outline-none focus:border-blue-500"
                    />
                </div>
            </div>

                <br />

                <div>

                </div>

                <div className={'text-center'}>

                    {!tokenVerified && <input type="checkbox" checked={tokenVerified} onClick={handleCaptchaCheck} />}
                    &nbsp;&nbsp;<label>I'm not a robot</label> &nbsp;&nbsp;
                    {tokenVerified && tokenChecked && <span className={'text-green-700'}>Verified!</span> }
                    {!tokenVerified && tokenChecked && <span className={'text-red-700'}>Unverified!</span> }
                    &nbsp;&nbsp;
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Submit Request
                    </button>
                </div>

            </form>

        </div>
    );
}


export default OrderPage;
