import api from '../utils/api';
import * as httpClient from "../utils/http";

export const createDraft = async (partName) => {
    try {
        const response = await httpClient.post('/quotations', { partName });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};
