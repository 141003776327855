import React from 'react';
import PageTitle from "../components/layout/PageTitle";
import ContainerMedium from "../components/layout/ContainerMedium";
import PageCard from "../components/layout/PageCard";
import logo from '../assets/images/fa-logo-insignia.png';

const ContactSubmittedPage = () => {
    return (
        <section>
            <PageTitle>Thank You!</PageTitle>
            <ContainerMedium>
                <PageCard>
                    <p className={'text-center m-5'}><strong>We're received your request and will be in touch with you.</strong></p>
                </PageCard>
            </ContainerMedium>
        </section>
    );
};

export default ContactSubmittedPage;
