import React from 'react';
import PageTitle from "../components/layout/PageTitle";
import ContainerMedium from "../components/layout/ContainerMedium";
import PageCard from "../components/layout/PageCard";

const AutoBlogPage = () => {
    return (
        <section>
            <PageTitle>Auto Blog - Coming Soon!</PageTitle>
            <ContainerMedium>
                <PageCard>
                    <p className={'text-center m-5 mt-0'}>
                        Our big bad automotive blog is in the making. We're loading it up with high quality content and creating an experience you'll love.
                    </p>
                    <p className={'text-center m-5'}>
                        When it's here, you will find a wealth of helpful information and tips that range in categories from car care, DIY diagnosis, saving fuel, driving safely on the road and lots more fun stuff.
                    </p>
                    <p className={'text-center m-5'}>
                        See you!
                    </p>
                </PageCard>
            </ContainerMedium>
        </section>
    );
};

export default AutoBlogPage;
